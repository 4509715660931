import Layout from "../components/global/Layout"
import React from "react"
import { queries } from "../breakpoints"
import styled from 'styled-components';
import GeneralHeader from "../components/global/GeneralHeader"
import CtaSection from "../components/sections/CtaSection"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import SectionWrapper from "../components/sections/SectionWrapper"
import BulletPoint from "../components/global/BulletPoint"
import ValuePropsSection from "../components/sections/ValuePropsSection"

const GroupOrdering = ({ data, location }) => {

  const valueProps = [
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649353678/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Group%20Ordering/EXPORT-GroupOrder-ValueProp1.png",
      headline:"Enjoy delicious savings on delivery",
      subheadline:"Pay one delivery fee per restaurant instead of fees for each individual order."
    },
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649353678/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Group%20Ordering/EXPORT-GroupOrder-ValueProp2.png",
      headline:"Cater to every taste",
      subheadline:"Grubhub has the most local picks compared to any other platform, which means you’re only an order away from discovering a new favorite spot."
    },
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933621/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/C-GroupOrdering/EXPORTS-Illustrations-GroupOrdering-DeliveryBag-1-Citrus.png",
      headline:"Cut food waste and overspending",
      subheadline:"Order the exact amount of food you need for each meal."
    }
  ]

  const bulletPoints = [
    {
      heading: "Scheduled Team Order",
      body:"Account admins manage the budget, send out restaurant choices and check out for everyone. Meals arrive at the same time, individually bagged and labeled with employee names for convenience."
    },
    {
      heading: "Group Order",
      body:"Anyone on your team can start a shared cart. Employees order together from a restaurant and use their meal budget to split the cost at checkout."
    }
  ]

  return (
    <Layout
      location={location}
      title={"Group Ordering | Grubhub Corporate Accounts"}
      description={"Grubhub Corporate Group Orders Fill out the form below and we’ll be in touch <!– Submit By clicking “Submit” or by using this site, you agree to Grubhub’s terms of use and privacy policy. –> Everyone’s order, delivered together. Group orders are a great way for your team to safely order whatever they’re craving […]"}
      noindex={true}
      nofollow={true}
      utag_data={{
        pageGroup: "",
        subGroup: "",
        pageName: "",
        brand: "grubhub"
      }}
      >
      <StyledNavbarOnlyLogo>
        <NavbarContainerOnlyLogo>
          <LogoHeader src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1663686777/HERMES/Logos/GH_Corporate_stacked-sRGB-OrangeGray.png" alt="Grubhub/Seamless Corporate Accounts" placeholder="blurred" width={196}/>
        </NavbarContainerOnlyLogo>
      </StyledNavbarOnlyLogo>
      <GeneralHeader
        headline="Cater to every taste with group ordering"
        preheader=""
        textColor="var(--color-secondary)"
        background="rgba(186,228,238,0.2)"
        formID={1812}
        formEnhancedConversions={true}
        businessEmailsUsage={true}
        businessEmailUTMLink='https://www.grubhub.com/lets-eat?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_about_ghc_sem'
        backgroundColorForm='#FFF'
        formHeading="Sign up to learn more"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796712/Grubhub%20Corporate%20Website/2021/Header%20Images/Subpage%20Headers/HEADERS-SUBPAGES-2560x1707-GroupOrdering.jpg"
      />
      <ValuePropsSection
        headline="Let your team order together"
        subheadline="With more group ordering options on Grubhub Corporate than on any other platform, you can feed any occasion while saving on fees and time. Plus there's no minimums for number of diners or orders."
        valueProps={valueProps}
        layout="vertical"
      />
      <SectionWrapper
        backgroundColor="rgba(186,228,238,0.1)"
      >
        <TwoWaysSection>
          <Headline>Two ways to Group Order</Headline>
          <Bullets>
            {bulletPoints.map((bullet, i) => (
              <BulletPoint
                heading={bullet.heading}
                body={bullet.body}
                markerText={(i + 1).toString()}
                key={i}
              />
            ))}
          </Bullets>
          <Image src="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797841/Grubhub%20Corporate%20Website/2021/Photography%20Elements/3-Subpage-CircleImages/EXPORTS-IMAGE-Circle-GroupOrdering.png"/>
        </TwoWaysSection>
      </SectionWrapper>
      <QuoteSection
        quote='"Our corporate account has simplified our food ordering process and alleviated administrative management thanks to group ordering, which works great for us. It eliminates the need to order individually."'
        author="Key Chong"
        position="Director of People at HP"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-HP.png"
        alt="HP logo"
        backgroundColor="#FFF"
      />
      <CtaSection
          headline="Discover the industry leader in flexible meal benefits"
          to="#general-header"
          backgroundColor="#F9F9F9"
      />
    </Layout>
  )
}

export default GroupOrdering

const TwoWaysSection = styled.div`
  display: grid;
  grid-template-areas: 
      "headline image"
      "bullets image"
  ;
  grid-template-columns: 6fr 4fr;
  @media (${queries.max_break_md}){
    grid-template-areas: 
      "headline"
      "image"
      "bullets";
    grid-template-columns: auto;
  }
`;

const Headline = styled.h2`
  grid-area: headline;
  font-size: var(--fontSize-5);
  font-weight: bold;
  margin-bottom: 25px;
  align-self:flex-end;
  @media (${queries.max_break_md}){
    text-align: center;
  }
`;
const Bullets = styled.div`
  grid-area: bullets;
`;
const Image = styled.img`
  grid-area: image;
  align-self: center;
  width:100%;
  @media (${queries.max_break_md}){
    margin: 20px 0%;
  }
`;

const StyledNavbarOnlyLogo = styled.header`
    -webkit-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    padding: 20px 0;
    z-index: 2000;
    position: relative;
`;

const NavbarContainerOnlyLogo = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
`;

const LogoHeader = styled.img`
    min-width: 200px;
    align-self: center;
`;
